<template lang="pug">
.code-insert-header
  .code-insert-header-container
    .code-insert-header_buttons.d-flex.justify-content-between.align-items-center(
      :class="paddingBottom"
    )
      om-button(ghost @click="back()") {{ $t('back') }}

      .d-flex
        om-button(v-if="hasConnectLater" ghost @click="connectLater()") {{ $t('codeInsertV2.header.connectLater') }}

        om-button.ml-2(v-if="hasCheck" primary :disabled="loading" @click="verify()") {{ $t('codeInsert.checkNow') }}

        om-button.ml-2(v-if="hasFinish" primary @click="finish()") {{ $t('finish') }}

        om-button.ml-2(v-if="hasNext" primary @click="next()") {{ $t('next') }}
</template>

<script>
  import { track } from '@/services/xray';

  export default {
    name: 'CodeInsertHeader',
    props: {
      status: {
        type: String,
        default: null,
        validator: (prop) => [null, 'loading', 'error'].includes(prop),
      },
      hasCheck: { type: Boolean, default: false },
      hasFinish: { type: Boolean, default: false },
      hasNext: { type: Boolean, default: false },
      pageId: { type: String, default: 'code-insert' },
      hasConnectLater: { type: Boolean, default: false },
    },
    computed: {
      buttonsIds() {
        return {
          back: `${this.pageId}-back`,
          skip: `${this.pageId}-connectLater`,
          next: `${this.pageId}-next`,
          finish: `${this.pageId}-finish`,
          check: `${this.pageId}-checkNow`,
        };
      },

      paddingBottom() {
        if (!this.hasCheck) {
          return;
        }
        return 'code-insert-header_buttons--bottom-padding';
      },

      loading() {
        return this.status === 'loading';
      },
    },
    methods: {
      emitEvent(event) {
        this.$emit('action', event);
      },

      back() {
        this.emitEvent('back');
        track(this.buttonsIds.back, null);
      },

      next() {
        this.emitEvent('finish');
        track(this.buttonsIds.next, null);
      },

      finish() {
        this.emitEvent('finish');
        track(this.buttonsIds.finish, null);
      },

      verify() {
        this.emitEvent('verify');
        track(this.buttonsIds.check, null);
      },

      connectLater() {
        this.emitEvent('finish');
        track(this.buttonsIds.skip, null);
      },
    },
  };
</script>

<style lang="sass">
  .code-insert-header
    container: card / inline-size
    background-color: #ffffff
    position: sticky
    z-index: 1
    top: 1rem
    &_buttons
      position: absolute
      width: 100%
      margin-top: -1rem
      padding: 1.25rem 0.25rem 0.75rem 0.25rem
      background-color: #ffffff
      @container (max-width: 250px)
        flex-direction: column
      &--bottom-padding
        padding-bottom: 1.1rem
        @container (max-width: 1000px)
          padding-bottom: 0.75rem
</style>
